import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";


import "../../stylesheets/application.scss?v=1.7";

import { Dropzone } from 'dropzone';
import $ from 'jquery';
import tinymce from 'tinymce/tinymce';
import moment from 'moment';
import select2 from 'select2';
import 'moment-timezone';
import 'bootstrap';

import 'tinymce/models/dom';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/skins/ui/oxide/skin.css';

import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/code';

import '../source/cleave.min.js';
import '../source/cleave-phone.us.js';
import '../source/jquery.timepicker.min.js';
import '../source/bootstrap-datepicker.js';
import '../source/community.js';

import contentUiSkinCss from '!!raw-loader!tinymce/skins/ui/oxide/content.css';

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.css';

Rails.start();
ActiveStorage.start();


console.log('test');
var dropzoneOpts = {
  paramName: "file", // The name that will be used to transfer the file
  maxFilesize: 15, // MB
  maxFiles: 1,
  acceptedFiles: "image/jpeg,image/png,image/gif,image/tiff",

  url: '/client/null',
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  },
  addRemoveLinks: true,

  accept: function(file, done) {
    console.log('__file', file);
    done();
  },

  init: function() {
    if($('.current-file').length) {
        this.addCustomFile = function(file, thumbnail_url , responce){
          // Push file to collection
          this.files.push(file);
          // Emulate event to create interface
          this.emit("addedfile", file);
          // Add thumbnail url
          this.emit("thumbnail", file, thumbnail_url);
          // Add status processing to file
          this.emit("processing", file);
          // Add status success to file AND RUN EVENT success from responce
          this.emit("success", file, responce , false);
          // Add status complete to file
          this.emit("complete", file);
        }

        this.addCustomFile(
          // File options
          {
              // flag: processing is complete
              processing: true,
              // flag: file is accepted (for limiting maxFiles)
              accepted: true,
              // name of file on page
              name: $('.current-file').attr('data-name'),
              // image size
              size: $('.current-file').attr('data-size'),
              // image type
              type: $('.current-file').attr('data-type') ,
              // flag: status upload
              status: Dropzone.SUCCESS
          },
          // Thumbnail url
          $('.current-file').attr('data-url'),
          // Custom responce for event success
          {
              status: "success"
          }
        );
    }

    this.on("addedfile", function() {
      if (this.files[1]!=null){
        this.removeFile(this.files[0]);
      }
    });
    this.on("processing", function(file) {
      this.options.url = this.element.getAttribute('data-url')
    });
    this.on("success", function(file, responseText) {
      var imageUrl;
      imageUrl = responseText.file_name.url;
      console.log('success!', imageUrl)
    });

  }
};

Dropzone.options.agreementMedia = dropzoneOpts;
Dropzone.options.siteSiteBanner = dropzoneOpts;
Dropzone.options.siteMailBanner = dropzoneOpts;

function notify(title, body) {
  if(typeof Notification === 'undefined') return;

  title = 'Reminder: '+title;
  var options = {
    body: body
  }
  if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    var notification = new Notification(title, options);
  
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        var notification = new Notification(title, options);
      } else {
        alert(title + "\n" + body);
      }
    });
  } else {
    alert(title + "\n" + body);
  }
}
function getRemindersForToday() {
  $.ajax({
    type: 'get',
    dataType: 'json',
    url: '/reminders',
    success: function(data, err) {
      if(data) window.reminders = data;
      console.log(data);
      if(typeof data === 'undefined') {
        window.clearInterval(window.timerInterval);
      }
      checkReminders();
    }
  })
}
function checkReminders() {
  if(moment().format('H:m') == '0:0') getRemindersForToday();
  console.log('checking reminders');
  for(x in window.reminders) {
    var start = moment(window.reminders[x].start.replace('Z','')).tz('America/Los_Angeles')
    console.log(window.reminders[x]);
    console.log(start.format('H:m'), moment().format('H:m'));
    if(start.format('H:m') == moment().format('H:m')) {
      notify(window.reminders[x].name, window.reminders[x].description); 
    }
  }
}
function initReminders() {
  if(typeof Notification === 'undefined') return;

  if (Notification.permission !== "granted") {
    Notification.requestPermission();
  }

  window.timerInterval = window.setInterval(checkReminders, 1000 * 60);
}
function setAgreementInputType(value) {
  $('[data-switch]').hide();
  $('[data-switch='+value+']').show();
}
function initPriceTableForm() {
  $('.add-price').click(function() {
    var row = $('.price-row').last(),
        new_row = row.clone();

    new_row.find('input').val('');
    row.after(new_row);
  });
  $('.add-service').click(function() {
    var row = $('.service-row').last(),
        new_row = row.clone();

    new_row.find('input, textarea').val('');
    row.after(new_row);
  });
  $('.rows').on('click', '.delete-row', function() {
    var rows = $(this).parents('.rows');
    if(rows.find('.row').length === 1) {
      rows.find('input, textarea').val('');
    } else {
      $(this).parents('.price-row, .service-row').remove();
    }
  });
}
function initCompanyUpdates() {
  $('.btn.copy').click(function() {
    var from = $(this).attr('data-copy-from'),
        to = $(this).attr('data-copy-to');

    if(from.indexOf(',') > -1) {
      from = from.split(',');
      to = to.split(',');
      from.forEach(function(f, index) {
        $('[name="company_update['+to[index]+']"]').val($('[name="company_update['+f+']"]').val());
      })
    } else {
      $('[name="company_update['+to+']"]').val($('[name="company_update['+from+']"]').val());
    }
  });
  $('.to-fields input, .to-fields select').each(function() {
    $(this).attr('data-initial-value', $(this).val());
  })
  $('.btn.undo').click(function() {
    var field = $(this).attr('data-reset');

    if(field.indexOf(',') > -1) {
      field = field.split(',');
      field.forEach(function(f) {
        $('[name="company_update['+f+']"]').val($('[name="company_update['+f+']"]').attr('data-initial-value'));
      })
    } else {
        $('[name="company_update['+field+']"]').val($('[name="company_update['+field+']"]').attr('data-initial-value'));
    }
  })
}
function setAssociationNameCharactersLeft() {
  if($('.community-name-field-max').length == 0) return;
    var number = $('.community-name-field-max').val().length;
    $('#association_name_characters_left span').text(41-number);
}
function initAgreementForm() {
  if(window.missing_fields) {
    window.missing_fields.forEach(function(field) {
      $('[name=agreement\\['+field+'\\]]').addClass('error');
    })
  }
  setAssociationNameCharactersLeft();
  $('.community-name-field-max').on('keydown keyup keypress blur change', function(event) {
    setAssociationNameCharactersLeft();
  });
  $('#agreement_submit_button').on("click", function (event) {
    event.preventDefault();


    if(window.confirm_submit) {
      $('#confirm_modal').modal('show');
      return false;
    } else {
      $(this).unbind('click').click();
    }

  });
  $("#close_modal").on("click", function (event) {
    event.preventDefault();
    $('#confirm_modal').modal('hide');

    return false;
  });
  $("#submit_modal").on("click", function (event) {
    event.preventDefault();
    window.confirm_submit = false;
    $('#confirm_modal').modal('hide');
    $('#agreement_submit_button').click();
    return false;
  });


  $('#agreement_manager_admin_same').change(function() {
    if($(this).is(':checked')) {
      $('.community-manager-section').hide();
    } else {
      $('.community-manager-section').show();

    }
  })
  $('#agreement_manager_admin_same').trigger('change');
  $('[data-switch]').hide();
  if($('[data-switch]').length) {
    if($('input.switch:checked').length) {
      var value = $('input.switch:checked').val();
      setAgreementInputType(value);
    }
    $('input.switch').change(function() {
      var value = $(this).val();
      setAgreementInputType(value);

    })
  }
}
function initCalendars(selector) {
  selector.datepicker({
    startDate: '01/01/1985',
    endDate: '12/31/2050',
    todayBtn: "linked",
    maxViewMode: 'years',
    assumeNearbyYear: true
  });
}  
function initCalendarsCustomStart(selector) {
  selector.datepicker({
    startDate: selector.attr('data-date-start-date'),
    endDate: '12/31/2050',
    todayBtn: "linked",
    maxViewMode: 'years',
    autoclose: true,
    assumeNearbyYear: true
  });
}  
function initDatepickers(selector, future) {
  if($(selector).length == 0) return;
  var opts = {
    date: true,
    datePattern: ['m', 'd', 'Y']
  };
  if(future) {
    opts.dateMin = moment().format('YYYY-MM-DD');
  }
  var cleave = new Cleave(selector, opts);
  selector.attr('placeholder','mm/dd/yyyy');
  selector.change(function() {
    $(this).val(moment($(this).val()).format('MM/DD/YYYY'));
  });
  // selector.datepicker({
  //   startDate: '01/01/1985',
  //   endDate: '12/31/2050',
  //   maxViewMode: 'years',
  //   assumeNearbyYear: true
  // });
}  
function initPhoneFields(selector) {
  if($(selector).length == 0) return;

  $(selector).toArray().forEach(function(field){
    var opts = {
      phone: true,
      delimiter: '-',
      phoneRegionCode: 'us'
    };

    var cleave = new Cleave(field, opts);

  })
  // selector.attr('placeholder','123-456-7890');

}  
function initTimepickers(selector) {
  selector.timepicker();
}  
var init = false;
function updateCommunitySelect() {
  $('.assign-community select option').removeAttr('disabled');
  $('.assigned-community').hide();
  $('.assign-new').removeClass('inactive');

  $('.assigned-communities-select option[selected=selected]').each(function() {
    var val = $(this).val();

    $('.assign-community select option[value='+val+']').attr('disabled','disabled');
    $('.assigned-community[data-id='+val+']').show();

  });
  $('.assign-community select').val('');
  $('.assign-community select').select2('destroy');
  $('.assign-community select').select2({ width: '100%' });


  

}
function getPaneHeight() {
  $('.tab-pane:visible').each(function() {
    $(this).removeAttr('style');

    var top = $(this).offset().top,
        height = window.innerHeight - top - 50;
    $('.tab-pane').height(height);
  })
}
function initNav() {
  $('.burger').click(function() {
    $('body').addClass('nav-open');
  })
  $('.nav > .close').click(function() {
    $('body').removeClass('nav-open');
  })
  $('body').on('click', '.alert-icon.visible', function(e) {
    e.preventDefault();
    var popover = $(this).next('.popover');
    popover.toggleClass('visible');
    return false;
  });
  $('body').on('click', '.alert .close', function() {
    var id = parseInt($(this).attr('data-alert-id')),
        alert = $(this).parents('.alert');
    $.ajax({
      type: 'post',
      url: '/alerts/'+id+'/done',
      dataType: 'json',
      data: {
        authenticity_token: AUTH_TOKEN
      },
      success: function(data, err) {
        console.log(data);
        if(data.id && data.id === id) {
          alert.addClass('out');
          window.setTimeout(function() {
            if($('.nav .popover .alert').length === 1) {
              $('.nav .popover').removeClass('visible');
              $('.alert-icon').delay(100).removeClass('visible');
            } else {
              alert.remove();
            }
          }, 200);
        }
      }
    })
  })
  window.setInterval(getAlerts, 15000);
  getAlerts();
  $(document).click(function(event) { 
    let $target = $(event.target);
    if(!$target.closest('.nav .popover, .nav .alert-icon').length) {
      $('.nav .popover').removeClass('visible');
    }        
  });

}
function getAlerts() {
  if($('.alert-icon').length === 0) return;
  $.ajax({
    type: 'get',
    url: '/alerts',
    dataType: 'json',
    data: {
      authenticity_token: AUTH_TOKEN
    },
    success: function(data, err) {
      console.log(data);
      if(data && data.length && data[0].id) {
        $('.alert-icon').addClass('visible');
        $('.nav .popover .alert').remove();
        data.forEach(function(alert) {
          var alert_text = alert.text.replaceAll('<a ', '<a target="_blank" ');
          $('.nav .popover .all').before('<div class="alert"><p>' + alert_text + '</p><a data-alert-id="'+ alert.id +'" class="close"></a></div>')
        })
      } else {
        $('.alert-icon').removeClass('visible');
        $('.nav .popover').removeClass('visible');
      }
      
    }
  })
}
function initTabSave() {
  var url = window.location.pathname;
  if(url === '/') url = '/reminders';
  
  var set_tab = localStorage.getItem(url+'_tabs');
  
  if(set_tab) {
    $('a.nav-link[href="'+set_tab+'"]').click();
  }

  $('a.nav-link[data-toggle=tab]').click(function() {
    var href = $(this).attr('href');
    localStorage.setItem(url+'_tabs', href);
  })
}
function initLoadAll() {
  $('.load-all').click(function() {
    $('.loader-overlay').addClass('visible');
  })
}

function initTabSort() {
  $('.tab-pane, .sort-pane').each(function() {
    var role = $(this).attr('aria-labelledby'),
        sort = localStorage.getItem(role+'-sort');

    if(role != '') {
      $(this).on('click', 'a[data-sort-trigger]', function(e) {
        e.preventDefault();

        var new_sort = $(this).attr('data-sort-trigger');
        sort = localStorage.getItem(role+'-sort');

        if(sort && sort.replace('-','') == new_sort) {
          if(sort.indexOf('-') == -1) {
            new_sort = '-'+new_sort;
          } 
        }
        console.log('sort!!', new_sort, sort);
        localStorage.setItem(role+'-sort', new_sort);
        doTabSort(role);

        return false;
      })

      if(sort) {
        doTabSort(role);
      }
    }
  })

}
function initSiteForm() {
  $('.simple_form.new_site').submit(function() {
    console.log('test');
    if($('#site_to_do_restore_community_id').val() == '') {
      return confirm('If this is a returning client, please restore any archived to-dos. Are you sure you want to continue?');
    }
  })
}
function doTabSort(role) {
  var sort = localStorage.getItem(role+'-sort'),
      asc = sort.indexOf('-') == -1,
      pane = $('.tab-pane[aria-labelledby='+role+'], .sort-pane[aria-labelledby='+role+']'),
      keyword = sort.replace('-','');

  console.log('sort!', role, sort);

  pane.find('a[data-sort-trigger').removeClass('active').removeClass('asc').removeClass('desc');
  pane.find('a[data-sort-trigger='+keyword+']').addClass('active');

  if(asc) {
    pane.find('a[data-sort-trigger='+keyword+']').addClass('asc');
  } else {
    pane.find('a[data-sort-trigger='+keyword+']').addClass('desc');
  }


  if(role != '' && sort) {
    pane.find('table tbody').find('tr').sort(function(a, b) {
      if(keyword.indexOf('__number') > -1) {
        if (asc) {
          return parseInt($('td[data-sort='+keyword+']', a).text()) - parseInt($('td[data-sort='+keyword+']', b).text()) 
        } else {
          return parseInt($('td[data-sort='+keyword+']', b).text()) - parseInt($('td[data-sort='+keyword+']', a).text()) 

        }
      } else {
        if (asc) {
          return $('td[data-sort='+keyword+']', a).text().localeCompare($('td[data-sort='+keyword+']', b).text());
        } else {
          return $('td[data-sort='+keyword+']', b).text().localeCompare($('td[data-sort='+keyword+']', a).text());
        }

      }
    }).appendTo(pane.find('table tbody'));
  }
}
var template_data;
$(document).on('select2:open', () => {
  document.querySelector('.select2-search__field').focus();
});

function runInit() {
  if(init) return;
  init = true;
  console.log('init');
  $('body').on('submit', 'form', function() {
    $(this).find('input[type=email], input[type=password]').each(function() {
      $(this).val($(this).val().trim());
    })
  });

  initTabSort();
  initLoadAll();
  initDatepickers($('.datepicker'), false);
  initPhoneFields($('input[type=tel]'));
  initDatepickers($('.datepicker-future'), true);
  initCalendars($('.timepicker'));
  initCalendarsCustomStart($('.timepicker-custom-start'));
  initTimepickers($('.time-field'));
  initTabSave();
  initSiteForm();
  // console.log('contentCss', contentCss.toString());
  // console.log('contentuiSkinCss', contentUiSkinCss.toString());


  tinymce.init({
    selector: 'textarea.edit, textarea.edit-simple',
    license_key: 'gpl',
    menubar: false,
    toolbar: [
      'bold italic',
    ],
    skin: false,
    content_css: false,
    content_style: contentUiSkinCss.toString() + '\n' + contentCss.toString(),


  });
  tinymce.init({
    selector: 'textarea.edit-complex',
    license_key: 'gpl',
    menubar: false,
    plugins: 'link image table lists code',
    indent_use_margin: true,
    toolbar: [
      'styleselect bold italic | outdent indent | bullist numlist | link image table | removeformat | code',
    ],
     skin: false,
     content_css: false,
    content_style: contentUiSkinCss.toString() + '\n' + contentCss.toString(),
    //,
    // images_upload_url: '/images/upload',
    // images_upload_base_path: '/some/basepath'
  });
  getRemindersForToday();
  initReminders();
  initAgreementForm();
  initCompanyUpdates();
  initPriceTableForm();
  getPaneHeight();
  initNav();
  $(window).resize(getPaneHeight);
  $('select:not(.no-search)').select2({ width: '100%' });


  $('.alerts .all').change(function() {
    if($(this).is(':checked')) {
      $('.alerts input[type=checkbox]').prop('checked',true);
    } else {
      $('.alerts input[type=checkbox]').prop('checked',false);

    }
  })
  // $('textarea.edit').tinymce();
  // $('textarea.edit-simple').tinymce();

  if($('.template-data').length) {
    template_data = JSON.parse($('.template-data').attr('data'));
  }

  $('#community_url').change(function() {
    if(window.hostname && $(this).val() != window.hostname) {
      $("[name='community[secret_issues]'], [name='community[secret_candidates]'], [name='community[cumulative_voting]']").attr('disabled','disabled');
    } else {
      $("[name='community[secret_issues]'], [name='community[secret_candidates]'], [name='community[cumulative_voting]']").removeAttr('disabled');
    }
  });
  $('#community_secret_issues, #community_secret_candidates, #community_cumulative_voting').change(function() {
    window.community_info_changed = true;
  });
  if(window.siteoptions && window.siteoptions) {
    console.log('testing asc');
    $('#community_secret_issues_label').append(' (from '+window.hostname+' site options)');
    $('#community_secret_candidates_label').append(' (from '+window.hostname+' site options)');
    $('#community_cumulative_voting_label').append(' (from '+window.hostname+' site options)');

    if(window.siteoptions['privateIssues'] == 'true') {
      $('#community_secret_issues').prop('checked',true);
      $('#community_secret_issues_noedit').text('√');
    }
    else {
      $('#community_secret_issues').prop('checked',false);
      $('#community_secret_issues_noedit').text('X');
    }
    
    if(window.siteoptions['privateCandidates'] == 'true') {
      $('#community_secret_candidates').prop('checked',true);
      $('#community_secret_candidates_noedit').text('√');
    }
    else {
      $('#community_secret_candidates').prop('checked',false);
      $('#community_secret_candidates_noedit').text('X');
    }

    if(window.siteoptions['hasCumulativeVoting'] == 'true'){
      $('#community_cumulative_voting').prop('checked',true);
      $('#community_cumulative_voting_noedit').text('√');
    }
    else {
      $('#community_cumulative_voting').prop('checked',false);
      $('#community_cumulative_voting_noedit').text('X');
    }
  } else {
    $('#community_secret_issues_label').append(' (from TTP settings)');
    $('#community_secret_candidates_label').append(' (from TTP settings)');
    $('#community_cumulative_voting_label').append(' (from TTP settings)');

  }
  $('.simple_form.edit_user').submit(function() {
    var messages = [];
    if(window.user_level == 'client' && $('#user_email').val() != '' && window.user_email != $('#user_email').val()) {
        messages.push('Notice: changing the email address will update the voting site email addresses of all communities for which this client is assigned as admin.');
    }
    if(messages.length) return confirm(messages.join("\n\n")+"\n\nAre you sure you want to continue?");

  });

  $('#community_form').submit(function() {
    var messages = [],
        secret_issues = $('#community_secret_issues').is(':checked') ? 'true' : 'false',
        secret_candidates = $('#community_secret_candidates').is(':checked') ? 'true' : 'false',
        cumulative_voting = $('#community_cumulative_voting').is(':checked') ? 'true' : 'false',
        text,
        send_renewals_to_other = $('#community_send_renewal_emails_to_other').val(),
        invalid_send_to = false;

    console.log('window_can_edit', window.can_edit_siteoptions);
    if($('#community_admin_id').val() != '' && window.admin_id != $('#community_admin_id').val()) {
        messages.push('Notice: changing the admin will update the voting site email address to the new admin\'s email.');
    }
    if(send_renewals_to_other && send_renewals_to_other.trim() != '') {
      send_renewals_to_other = send_renewals_to_other.replace('\r','');
      send_renewals_to_other = send_renewals_to_other.split('\n');

      send_renewals_to_other.forEach(function(email) {
        if(email.trim() == '') return;
        if(email.trim().match(/^\S+@\S+\.\S+$/) == null) {
          invalid_send_to = true;
        }
      })      
    }
    if(invalid_send_to) {
      messages.push('Notice: invalid email in "send renewal emails to" field.');
    }

    if(window.can_edit_siteoptions && window.siteoptions && (secret_issues != window.siteoptions['privateIssues'] || secret_candidates != window.siteoptions['privateCandidates'] || cumulative_voting != window.siteoptions['hasCumulativeVoting'])) {
      if(window.hostname && $('#community_url').val() != window.hostname) {
        messages.push('Notice: changing site url, any changes to private issues, candidates, or cumulative voting will be ignored. Please make changes to those after updating site url.');
      } else {


        text = 'Warning: you are about to change some of the site options for '+window.hostname+':';
        if(secret_issues != window.siteoptions['privateIssues']) {
          text += "\n"+'Private issues: (was '+window.siteoptions['privateIssues']+', now '+secret_issues+')';
        }
        if(secret_candidates != window.siteoptions['privateCandidates']) {
          text += "\n"+'Private candidates: (was '+window.siteoptions['privateCandidates']+', now '+secret_candidates+')';
        }
        if(cumulative_voting != window.siteoptions['hasCumulativeVoting']) {
          text += "\n"+'Cumulative voting: (was '+window.siteoptions['hasCumulativeVoting']+', now '+cumulative_voting+')';
        }
        messages.push(text);
      }

    } else if (window.can_edit_siteoptions && window.community_info_changed && window.hostname) {
      messages.push('Notice: changing private issues, candidates, or cumulative voting will update the site options for '+window.hostname+'.');

    }
    if(window.renewal_date && window.renewal_date != '' && window.renewal_date != $('#community_renewal_date').val()) {
      messages.push('Warning: changing the renewal date will reset the follow-up dates and flags (potentially causing the renewal follow-ups to be re-sent), and hide all renewal emails from the sales page.');
      if(moment(window.renewal_date) < moment().add(60, 'days') && moment($('#community_renewal_date').val()) > moment().add(60, 'days')) {
        messages.push('Warning: changing the renewal date to over 60 days in the future will remove the locked-in price table for this community, and it will reset to the current price table.');

      }
    }
    if(typeof window.price_table_id != 'undefined' && window.price_table_id != $('.community_price_table_select').val()) {
      messages.push('Warning: changing the assigned price table will immediately change the pricing for this community and its future estimates (including follow-ups and renewals).');
    }
    if(messages.length) return confirm(messages.join("\n\n")+"\n\nAre you sure you want to continue?");
  });
  $('#community_self_serve').change(function() {
    console.log('#community_self_serve changed', $(this).is(':checked'), $('#community_vote_manager_id').length);

    if($(this).is(':checked') && $('#community_vote_manager_id').length) {
      var self_serve = $('#community_vote_manager_id option:contains(Self Serve)');
      console.log(self_serve, self_serve.attr('value'));
      if(self_serve) $('#community_vote_manager_id').val(self_serve.attr('value'));
    }
  })

  $('.drop-site').click(function(e) {
    if($(this).hasClass('btn-warning') || $(this).hasClass('disabled')) {
     e.preventDefault();
      $(this).parents('tr').find('.site-drop-confirm').show();
      $(this).addClass('btn-danger disabled')
        .removeClass('btn-warning')
        .html('Confirm Drop Site');
        
      return false;
    }
  })
  $('.site-drop-confirm').keyup(function() {
    var should = $(this).attr('data-validate');
    var btn = $(this).parents('tr').find('.btn-danger');

   // console.log(should, $(this).val());

    if($(this).val() == should) {
      btn.removeClass('disabled');
      btn.attr('href',btn.attr('data-href'));

    } else {
      btn.addClass('disabled');
      btn.attr('href','#');
    }
  })

  $('.toggle-resend').change(function() {
    if($('.toggle-resend:checked').length == 0) {
      $('.resend').addClass('hide');
    } else {
      $('.resend').removeClass('hide');
    }
  })
  $('.toggle-resend').trigger('change');

  $('.edit_vote_report input, .edit_vote_report select').change(function() {
    console.log('changed');

    $(window).on('beforeunload', function() {
      return 'You have unsaved changes that will be lost if you don\'t save.';
    });

  });
  $('.edit_vote_report').on('submit', function() {
    var data = new FormData($(this)[0]),
      has_daily_item = false;
    [
     'quorum_send_votes_received',
     'candidate_send_votes_received',
     'issue_send_votes_received',
     'candidate_send_totals',
     'issue_send_totals'
    ].forEach((field) => {
      var f = 'vote_report['+field+']',
          value = data.getAll(f);

      if(typeof value.slice === 'undefined' || value.length == 0) return;

      value = value.slice(-1).pop();

      if(value === '0' || value === 'false') return;

      console.log('thisitem: ', f, value);

      has_daily_item = true;

    });
    if(!has_daily_item) {
      alert('Notice: nothing is selected for the daily vote report, so no vote report will be sent.')
    }
    $(window).off('beforeunload');
  });


  $('#company_billing_same').change(function() {
    if($(this).is(':checked')) {
      $('#billing_address_address_1').val($('#address_address_1').val());
      $('#billing_address_city').val($('#address_city').val());
      $("#billing_address_state").val($('#address_state').val()).trigger('change');
      // $('#billing_address_state').select2('val', $('#address_state').val());

      $('#billing_address_zip').val($('#address_zip').val());
      $('.billing-field').addClass('hidden');
    } else {
      $('.billing-field').removeClass('hidden');

    }
  });
  $('.template-select').change(function() {
    var val = $(this).val();
    var this_template;
    for(x in template_data) {
      if(template_data[x].id == val) {
        this_template = template_data[x];
      }
    }
    if(this_template) {
      $('#message_subject').val(this_template.subject);
      $('#message_to').val(this_template.to);
      tinymce.get('message_body').setContent(this_template.body);
      if(this_template.send_now) $('#message_send_now').attr('checked','checked');
      else  $('#message_send_now').removeAttr('checked');
      $('#message_send_on').val(this_template.send_on);
      $('#message_send_every_number').val(this_template.send_every_number);
      $('#message_send_every_interval').val(this_template.send_every_interval);
      $('#message_times_to_send').val(this_template.times_to_send);
    }
    console.log(this_template);
  });
  updateCommunitySelect();
  if($('.service-reason').length) {
    $('.another-service').click(function() {
      $('.service-reason').show();
    })
    $('input[type=radio]:not(.another-service)').click(function() {
      $('.service-reason').hide();
    })
    $('.follow-up-again').click(function() {
      $('.when-follow-up').show();
    })
    $('input[type=radio]:not(.follow-up-again)').click(function() {
      $('.when-follow-up').hide();
    })
    $('.other-show').click(function() {
      $('.other-field').show();
    })
    $('input[type=radio]:not(.other-show)').click(function() {
      $('.other-field').hide();
    })
  }
  $('.assign-new').click(function(e) {
    e.preventDefault();
    var val = $('.assign-community select').find(':selected').val();
    if(!val || val.trim() == '') return;
    if($(this).hasClass('inactive')) return;

    $('.assigned-communities-select option[value='+val+']').attr('selected','selected');
    updateCommunitySelect();
    return false;
  });

  $('.unassign-community').click(function(e) {
    e.preventDefault();
    var val = $(this).attr('data-id');

    $('.assigned-communities-select option[value='+val+']').removeAttr('selected');
    updateCommunitySelect();
    return false;

  });
  $('body').on('change', '#reminder_user_id, #reminder_start, #reminder_start_time', function() {
    var user_id = $('#reminder_user_id').val();
    var reminder_id = $('#reminder_id').val();
    var start = $('#reminder_start').val();
    var start_time = $('#reminder_start_time').val();
    console.log('test', user_id, start, start_time, AUTH_TOKEN);


    $.ajax({
      type: 'post',
      url: '/reminders/check_duplicate',
      data: {
        reminder: {
          reminder_id: reminder_id,
          user_id: user_id,
          start_date: start,
          start_time: start_time
        },
        authenticity_token: AUTH_TOKEN
      },
      success: function(data, err) {
        console.log(data, err);
        if(data && data.length) {
          var reminders = [];
          for(x in data) {
            reminders.push(data[x].name)
          }
          reminders = reminders.join(', ');
          $('.notice.conflicts').show();
          $('.notice.conflicts .reminders').html(reminders);
        } else {
          $('.notice.conflicts').hide();
        }
      }
    })
  });
  $('.show-hide-password').click(function() {
    if($(this).hasClass('show-password')) {
      $('.show-hide-password').removeClass('show-password');
      $('.password-field input').attr('type', 'password');
    } else {
      $('.show-hide-password').addClass('show-password');
      $('.password-field input').attr('type', 'text');
    }
  })
  $('#message_send_now').change(function() {
    if($(this).is(':checked')) {
      $('#message_send_on').val(moment().format('MM/DD/YYYY'));
    }
  });
  $('#message_send_on').keyup(function() {
    $('#message_send_now').prop('checked', false);
  });

  $('#community_suspend_next_invoice, #community_admin_access').click(function() {
    $(this).parents('form').submit();
  });
  $('.search-category').click(function() {
    $('body').addClass('search');
  });
  $('.clear-search').click(function() {
    $('body').removeClass('search');
  });
  $('select[data-toggle]').change(function() {
    toggleTarget($(this));
  });
  $('select[data-toggle]').each(function() {
    toggleTarget(this);
  });
  function toggleTarget(obj) {
    var target = $(obj).attr('data-toggle');
    if($(obj).val() == '') {
      $(target).show();
    } else {
      $(target).hide();
    }
  }
  $('#reminders_calendar').datepicker({
    startDate: '1985-01-01',
    endDate: '2050-12-31',
    maxViewMode: 'years',
    format: 'yy-mm-dd'
    //#TODO: date not being set properly?
  }).on('changeDate', function(e) {
    var date = e.date.toISOString().split('T')[0];
    var mode = $('#reminders_calendar').attr('data-mode');
    if(mode == 'popup') 
      window.location.href = '/reminders/popup/'+date;
    else 
      window.location.href = '/reminders/index/'+date;

  });
  $('#to_dos_calendar').datepicker({
    startDate: '2000-01-01',
    endDate: '2050-12-31',
    maxViewMode: 'years',
    format: 'yy-mm-dd',
    todayBtn: 'linked'
    //#TODO: date not being set properly?
  }).on('changeDate', function(e) {
    var date = e.date.toISOString().split('T')[0];
    // console.log('show date: '+date);
    // $('#show_all_to_dos').removeClass('inactive');
    // $('.to-do').hide();
    // $('.to-do[data-date="'+date+'"]').show();
    window.location.href = window.location.pathname+'?date='+date;

  });
  $('#show_all_to_dos').click(function() {
    $('#show_all_to_dos').addClass('inactive');
    $('.to-do').show();
  })
}
document.addEventListener('DOMContentLoaded', runInit);
document.addEventListener('pageshow', runInit);
// document.addEventListener('pageshow', runinit);
$( document ).on('turbolinks:load', runInit);
// $(window).on('pageshow', runInit);

